import { DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH } from 'common/constants';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { CONTENT_MAX_WIDTH } from '@leagueplatform/web-common-components';
import { useUrlParam } from '@leagueplatform/web-common';
import { PageHeaderMobile } from 'components/page-header-mobile/page-header-mobile.component';
import { PropsWithChildren } from 'react';

export const GenericMasonryPageWrapper = ({
  children,
}: PropsWithChildren<{}>) => {
  const { urlParam: titleString } = useUrlParam('title');

  return (
    <StackLayout
      orientation="vertical"
      css={{
        padding: '$none $one',
        '@desktop': {
          padding: '$none $two',
          maxWidth: CONTENT_MAX_WIDTH,
          margin: '$none auto',
          marginBottom: '$two',
        },
      }}
    >
      {titleString && <PageHeaderMobile headingText={titleString} />}
      <Box
        css={{
          maxWidth: DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH,
          width: '100%',
          '@desktop': {
            marginLeft: '$twoAndHalf',
          },
          '@laptop': {
            marginLeft: '$twoAndHalf',
          },
        }}
      >
        {children}
      </Box>
    </StackLayout>
  );
};
